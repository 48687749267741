import { default as _91_46_46_46slug_93u8RG6UjKFPMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/[...slug].vue?macro=true";
import { default as adressenW2vp2kuD4uMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/bedrijfsgegevens/[company]/adressen.vue?macro=true";
import { default as indexcW9gxWwSbyMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/bedrijfsgegevens/[company]/index.vue?macro=true";
import { default as medewerkersYU0Dh98FPgMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/bedrijfsgegevens/[company]/medewerkers.vue?macro=true";
import { default as indexOQRWBGVNgzMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/bedrijfsgegevens/index.vue?macro=true";
import { default as indexVBO1BNaAFUMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/communicatievoorkeuren/index.vue?macro=true";
import { default as indexDApRt69Ti8Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/evenementen/index.vue?macro=true";
import { default as _91factuur_93Il93ThD5YgMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/facturen/[factuur].vue?macro=true";
import { default as indexeMkZKlOaY8Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/facturen/index.vue?macro=true";
import { default as index3Pbw4d6oA2Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/index.vue?macro=true";
import { default as indexnyIPwwSoceMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/ledenvoordelen/index.vue?macro=true";
import { default as incassotrvbHwJO5tMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue?macro=true";
import { default as indexAlrBFrivdCMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/index.vue?macro=true";
import { default as indexSHXp2EgfWhMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/lidmaatschap/index.vue?macro=true";
import { default as nieuwsbriefvoorkeuren5ddwGat2OJMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/nieuwsbriefvoorkeuren.vue?macro=true";
import { default as accountfEjWLw7Gn0Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account.vue?macro=true";
import { default as loginTpJ7BRX2wBMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/auth/login.vue?macro=true";
import { default as _91token_93hyJtEv9gFyMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/auth/wachtwoord-instellen/[token].vue?macro=true";
import { default as _91token_93utMo4PtAf0Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/auth/wachtwoord-vergeten/[token].vue?macro=true";
import { default as indexYtOlPBzwehMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/auth/wachtwoord-vergeten/index.vue?macro=true";
import { default as _91slug_932e62GkzCdPMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/bestuurders/[slug].vue?macro=true";
import { default as bestuurderstrainingen_45oldmY9qYIfhPXMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/bestuurders/bestuurderstrainingen-old.vue?macro=true";
import { default as dossiers_45documentenMax89CKbgUMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/bestuurders/dossiers-documenten.vue?macro=true";
import { default as indexFpdwnNwD7bMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/bestuurders/index.vue?macro=true";
import { default as bestuurdersx2glI8QPN5Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/bestuurders.vue?macro=true";
import { default as dashboardidvB6rIC4XMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/dashboard.vue?macro=true";
import { default as evenementenueC4lEPt7aMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/evenementen.vue?macro=true";
import { default as indexGPk3AR9YHtMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/index.vue?macro=true";
import { default as kennisbankpVpE6BJtLZMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/kennisbank.vue?macro=true";
import { default as _91id_936LA5eS4jJyMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/klanten/[id].vue?macro=true";
import { default as indextOTbqbjBtSMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/klanten/index.vue?macro=true";
import { default as afnemenhFb6CUi8U6Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/ledenvoordelen/[slug]/afnemen.vue?macro=true";
import { default as indexesauvqdLpBMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/ledenvoordelen/[slug]/index.vue?macro=true";
import { default as indexskEZccHj99Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/ledenvoordelen/index.vue?macro=true";
import { default as ledenvoordelenHZmXuxeBIUMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/ledenvoordelen.vue?macro=true";
import { default as indexi1QT9crC8OMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/lid-worden/index.vue?macro=true";
import { default as uitschrijven05RULzX7qIMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/nieuwsbrief/uitschrijven.vue?macro=true";
import { default as authorize2JpyNiCBLCMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/oauth/authorize.vue?macro=true";
import { default as stylessC29fEj0R2Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/styles.vue?macro=true";
import { default as aangemeldFYfAMUGJO4Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/trainingen/[slug]/[uuid]/aangemeld.vue?macro=true";
import { default as aanmeldenrdOuegIl6kMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/trainingen/[slug]/[uuid]/aanmelden.vue?macro=true";
import { default as indexi8m7ggpPpUMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/trainingen/[slug]/index.vue?macro=true";
import { default as indexBKY2Ewa3m6Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/trainingen/index.vue?macro=true";
import { default as trainingenmrXxqJ0NJeMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/trainingen.vue?macro=true";
import { default as _91id_93jexyCKh8diMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/verkoopkansen/[id].vue?macro=true";
import { default as indexe8jf0HidHmMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/verkoopkansen/index.vue?macro=true";
import { default as _91product_93ibaAwQTzKwMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop/[category]/[product].vue?macro=true";
import { default as indexCMyPc5GqS0Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop/[category]/index.vue?macro=true";
import { default as cartBE5CEBtwmPMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop/cart.vue?macro=true";
import { default as checkoutksUyltijKAMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop/checkout.vue?macro=true";
import { default as confirmationKX9xdPUZpGMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop/confirmation.vue?macro=true";
import { default as indexr1qXw0rPH8Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop/index.vue?macro=true";
import { default as webshopFN3d21fEF4Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop.vue?macro=true";
import { default as zoekenclAhPd95lWMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/zoeken.vue?macro=true";
import { default as aanmeldenWQsGyvV5M6Meta } from "~/pages/trainingen/[slug]/[uuid]/aanmelden.vue?macro=true";
import { default as component_45stubvSQH5670x0Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_cppib6rmmk4g4zctnpublec37e/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubvSQH5670x0 } from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_cppib6rmmk4g4zctnpublec37e/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93u8RG6UjKFPMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/[...slug].vue")
  },
  {
    name: accountfEjWLw7Gn0Meta?.name,
    path: "/account",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account.vue"),
    children: [
  {
    name: "account-bedrijfsgegevens-company-adressen",
    path: "bedrijfsgegevens/:company()/adressen",
    meta: adressenW2vp2kuD4uMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/bedrijfsgegevens/[company]/adressen.vue")
  },
  {
    name: "account-bedrijfsgegevens-company",
    path: "bedrijfsgegevens/:company()",
    meta: indexcW9gxWwSbyMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/bedrijfsgegevens/[company]/index.vue")
  },
  {
    name: "account-bedrijfsgegevens-company-medewerkers",
    path: "bedrijfsgegevens/:company()/medewerkers",
    meta: medewerkersYU0Dh98FPgMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/bedrijfsgegevens/[company]/medewerkers.vue")
  },
  {
    name: "account-bedrijfsgegevens",
    path: "bedrijfsgegevens",
    meta: indexOQRWBGVNgzMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/bedrijfsgegevens/index.vue")
  },
  {
    name: "account-communicatievoorkeuren",
    path: "communicatievoorkeuren",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/communicatievoorkeuren/index.vue")
  },
  {
    name: "account-evenementen",
    path: "evenementen",
    meta: indexDApRt69Ti8Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/evenementen/index.vue")
  },
  {
    name: "account-facturen-factuur",
    path: "facturen/:factuur()",
    meta: _91factuur_93Il93ThD5YgMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/facturen/[factuur].vue")
  },
  {
    name: "account-facturen",
    path: "facturen",
    meta: indexeMkZKlOaY8Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/facturen/index.vue")
  },
  {
    name: "account",
    path: "",
    meta: index3Pbw4d6oA2Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/index.vue")
  },
  {
    name: "account-ledenvoordelen",
    path: "ledenvoordelen",
    meta: indexnyIPwwSoceMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/ledenvoordelen/index.vue")
  },
  {
    name: "account-lidmaatschap-lidmaatschap-incasso",
    path: "lidmaatschap/:lidmaatschap()/incasso",
    meta: incassotrvbHwJO5tMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue")
  },
  {
    name: "account-lidmaatschap-lidmaatschap",
    path: "lidmaatschap/:lidmaatschap()",
    meta: indexAlrBFrivdCMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/index.vue")
  },
  {
    name: "account-lidmaatschap",
    path: "lidmaatschap",
    meta: indexSHXp2EgfWhMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/lidmaatschap/index.vue")
  },
  {
    name: "account-nieuwsbriefvoorkeuren",
    path: "nieuwsbriefvoorkeuren",
    meta: nieuwsbriefvoorkeuren5ddwGat2OJMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/account/nieuwsbriefvoorkeuren.vue")
  }
]
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginTpJ7BRX2wBMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/auth/login.vue")
  },
  {
    name: "auth-wachtwoord-instellen-token",
    path: "/auth/wachtwoord-instellen/:token()",
    meta: _91token_93hyJtEv9gFyMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/auth/wachtwoord-instellen/[token].vue")
  },
  {
    name: "auth-wachtwoord-vergeten-token",
    path: "/auth/wachtwoord-vergeten/:token()",
    meta: _91token_93utMo4PtAf0Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/auth/wachtwoord-vergeten/[token].vue")
  },
  {
    name: "auth-wachtwoord-vergeten",
    path: "/auth/wachtwoord-vergeten",
    meta: indexYtOlPBzwehMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/auth/wachtwoord-vergeten/index.vue")
  },
  {
    name: bestuurdersx2glI8QPN5Meta?.name,
    path: "/bestuurders",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/bestuurders.vue"),
    children: [
  {
    name: "bestuurders-slug",
    path: ":slug()",
    meta: _91slug_932e62GkzCdPMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/bestuurders/[slug].vue")
  },
  {
    name: "bestuurders-bestuurderstrainingen-old",
    path: "bestuurderstrainingen-old",
    meta: bestuurderstrainingen_45oldmY9qYIfhPXMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/bestuurders/bestuurderstrainingen-old.vue")
  },
  {
    name: "bestuurders-dossiers-documenten",
    path: "dossiers-documenten",
    meta: dossiers_45documentenMax89CKbgUMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/bestuurders/dossiers-documenten.vue")
  },
  {
    name: "bestuurders",
    path: "",
    meta: indexFpdwnNwD7bMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/bestuurders/index.vue")
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/dashboard.vue")
  },
  {
    name: "evenementen",
    path: "/evenementen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/evenementen.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexGPk3AR9YHtMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/index.vue")
  },
  {
    name: "kennisbank",
    path: "/kennisbank",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/kennisbank.vue")
  },
  {
    name: "klanten-id",
    path: "/klanten/:id()",
    meta: _91id_936LA5eS4jJyMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/klanten/[id].vue")
  },
  {
    name: "klanten",
    path: "/klanten",
    meta: indextOTbqbjBtSMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/klanten/index.vue")
  },
  {
    name: ledenvoordelenHZmXuxeBIUMeta?.name,
    path: "/ledenvoordelen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/ledenvoordelen.vue"),
    children: [
  {
    name: "ledenvoordelen-slug-afnemen",
    path: ":slug()/afnemen",
    meta: afnemenhFb6CUi8U6Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/ledenvoordelen/[slug]/afnemen.vue")
  },
  {
    name: "ledenvoordelen-slug",
    path: ":slug()",
    meta: indexesauvqdLpBMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/ledenvoordelen/[slug]/index.vue")
  },
  {
    name: "ledenvoordelen",
    path: "",
    meta: indexskEZccHj99Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/ledenvoordelen/index.vue")
  }
]
  },
  {
    name: "lid-worden",
    path: "/lid-worden",
    meta: indexi1QT9crC8OMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/lid-worden/index.vue")
  },
  {
    name: "nieuwsbrief-uitschrijven",
    path: "/nieuwsbrief/uitschrijven",
    meta: uitschrijven05RULzX7qIMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/nieuwsbrief/uitschrijven.vue")
  },
  {
    name: "oauth-authorize",
    path: "/oauth/authorize",
    meta: authorize2JpyNiCBLCMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/oauth/authorize.vue")
  },
  {
    name: "styles",
    path: "/styles",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/styles.vue")
  },
  {
    name: trainingenmrXxqJ0NJeMeta?.name,
    path: "/trainingen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/trainingen.vue"),
    children: [
  {
    name: "trainingen-slug-uuid-aangemeld",
    path: ":slug()/:uuid()/aangemeld",
    meta: aangemeldFYfAMUGJO4Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/trainingen/[slug]/[uuid]/aangemeld.vue")
  },
  {
    name: "trainingen-slug-uuid-aanmelden",
    path: ":slug()/:uuid()/aanmelden",
    meta: aanmeldenrdOuegIl6kMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/trainingen/[slug]/[uuid]/aanmelden.vue")
  },
  {
    name: "trainingen-slug",
    path: ":slug()",
    meta: indexi8m7ggpPpUMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/trainingen/[slug]/index.vue")
  },
  {
    name: "trainingen",
    path: "",
    meta: indexBKY2Ewa3m6Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/trainingen/index.vue")
  }
]
  },
  {
    name: "verkoopkansen-id",
    path: "/verkoopkansen/:id()",
    meta: _91id_93jexyCKh8diMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/verkoopkansen/[id].vue")
  },
  {
    name: "verkoopkansen",
    path: "/verkoopkansen",
    meta: indexe8jf0HidHmMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/verkoopkansen/index.vue")
  },
  {
    name: webshopFN3d21fEF4Meta?.name,
    path: "/webshop",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop.vue"),
    children: [
  {
    name: "webshop-category-product",
    path: ":category()/:product()",
    meta: _91product_93ibaAwQTzKwMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop/[category]/[product].vue")
  },
  {
    name: "webshop-category",
    path: ":category()",
    meta: indexCMyPc5GqS0Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop/[category]/index.vue")
  },
  {
    name: "webshop-cart",
    path: "cart",
    meta: cartBE5CEBtwmPMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop/cart.vue")
  },
  {
    name: "webshop-checkout",
    path: "checkout",
    meta: checkoutksUyltijKAMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop/checkout.vue")
  },
  {
    name: "webshop-confirmation",
    path: "confirmation",
    meta: confirmationKX9xdPUZpGMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop/confirmation.vue")
  },
  {
    name: "webshop",
    path: "",
    meta: indexr1qXw0rPH8Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/webshop/index.vue")
  }
]
  },
  {
    name: "zoeken",
    path: "/zoeken",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/pages/zoeken.vue")
  },
  {
    name: "bestuurders-trainingen-slug-uuid-aanmelden",
    path: "/bestuurders/trainingen/:slug()/:uuid()/aanmelden",
    meta: aanmeldenWQsGyvV5M6Meta || {},
    component: () => import("~/pages/trainingen/[slug]/[uuid]/aanmelden.vue")
  },
  {
    name: component_45stubvSQH5670x0Meta?.name,
    path: "/evenementen/:pathMatch(.*)",
    component: component_45stubvSQH5670x0
  }
]