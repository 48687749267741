import revive_payload_client_rYyXVpE8ej from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_cppib6rmmk4g4zctnpublec37e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LoLaX3UuQf from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_cppib6rmmk4g4zctnpublec37e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ysNSSH1b0b from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_cppib6rmmk4g4zctnpublec37e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_IbYY0iGdIc from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_terser@5_cs6t4vedpr44vml254pcw67c5m/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kYfnVFvYSB from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_cppib6rmmk4g4zctnpublec37e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_DsSZatnqn0 from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_cppib6rmmk4g4zctnpublec37e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Novq2kbIQ0 from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_cppib6rmmk4g4zctnpublec37e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_liFygYw6aX from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_cppib6rmmk4g4zctnpublec37e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/.nuxt/components.plugin.mjs";
import prefetch_client_8AM9hv3hRm from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_cppib6rmmk4g4zctnpublec37e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jXnIJXwPLQ from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.28.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_HJQnUhYYlh from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.0_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5Dk454CHw9 from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.0_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formkitPlugin_pZqjah0RUG from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/.nuxt/formkitPlugin.mjs";
import plugin_Gh2IFXECgF from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.2_graphql-ws@5.16.0_graphql@16.9.0__react-dom@18.3.1_react@_vq4qirhqtzxgqglwy36wtzikiy/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_1VBy55ka37 from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.26.0_@ope_pesqjper55bdjququalbzsm2wy/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import floating_vue_EIcJ7xiw0h from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/.nuxt/floating-vue.mjs";
import plugin_dpWWOBeiuL from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+n_zihq7grgnvlh6m67d5fobn7v5m/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import errors_he2QKO42eK from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_@unhead+vue@1.11.13_vue@3.5.13_typescri_ve5yy7db44kpgkvnsmvmxcqsqm/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_Bl3enHJOlO from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_@unhead+vue@1.11.13_vue@3.5.13_typescri_ve5yy7db44kpgkvnsmvmxcqsqm/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_JyBbyhF2Gc from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.2_@bugsnag+core@7.25.0_@unhead+vue@1.11.13_vue@3.5.13_typescr_drgxbyyegtzuqnwremchisirry/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_EUALEtJ5yZ from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.2_@bugsnag+core@7.25.0_@unhead+vue@1.11.13_vue@3.5.13_typescr_drgxbyyegtzuqnwremchisirry/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_3qtR2XlAH6 from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.3_graphql-ws@5.16.0_graphql@16.9.0__magicast@0.3.5_next@_66j4wjk2wo5bewhzkpkpg33yni/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_bsJzYKuTHu from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.3_graphql-ws@5.16.0_graphql@16.9.0__magicast@0.3.5_next@_66j4wjk2wo5bewhzkpkpg33yni/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import api_pLXqdbxjFa from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/core/plugins/api.ts";
import apollo_tALwIKkKAT from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/core/plugins/apollo.ts";
import vue3_toastify_FRI1XjPElg from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/core/plugins/vue3-toastify.ts";
import v_calendar_dxaVzst4iS from "/data/www/mijn.khn.nl/mijn-khn/releases/14/nuxt/website/plugins/v-calendar.ts";
export default [
  revive_payload_client_rYyXVpE8ej,
  unhead_LoLaX3UuQf,
  router_ysNSSH1b0b,
  _0_siteConfig_IbYY0iGdIc,
  payload_client_kYfnVFvYSB,
  navigation_repaint_client_DsSZatnqn0,
  check_outdated_build_client_Novq2kbIQ0,
  chunk_reload_client_liFygYw6aX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8AM9hv3hRm,
  plugin_jXnIJXwPLQ,
  switch_locale_path_ssr_HJQnUhYYlh,
  i18n_5Dk454CHw9,
  formkitPlugin_pZqjah0RUG,
  plugin_Gh2IFXECgF,
  plugin_1VBy55ka37,
  floating_vue_EIcJ7xiw0h,
  plugin_dpWWOBeiuL,
  errors_he2QKO42eK,
  i18n_Bl3enHJOlO,
  apollo_JyBbyhF2Gc,
  sofie_required_version_EUALEtJ5yZ,
  apollo_3qtR2XlAH6,
  auth_bsJzYKuTHu,
  api_pLXqdbxjFa,
  apollo_tALwIKkKAT,
  vue3_toastify_FRI1XjPElg,
  v_calendar_dxaVzst4iS
]